import React from "react";
import { Helmet } from 'react-helmet'

import {SmallHero, Header, Footer} from "../fragments";
import Divider from "../components/Divider";

const Blog = () => {
  return (
    <main className="overflow-hidden">
      <Helmet>
        <title>Wisoftify - Blog</title>
      </Helmet>
      <Header />
      <SmallHero title="Blog" />
      <div className="bg-gray-100 pt-8 pb-6 flex items-center justify-center" style={{height: 300}} >
        <h2 className="text-center">Coming Soon ...</h2>
      </div>
      <Footer />
    </main>
  )
}

export default Blog;