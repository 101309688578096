import React from "react";

const Divider = (props) => {
  const {
    height,
    className,
    style
  } = props;

  return (
    <div className={`"w-full ${className ?? ""}`} style={{height, minHeight: height, maxHeight: height, ...style}}>
      <div className="h-full m-auto" style={{width: 2, borderLeft: "3px dotted #3CA4FA"}}>

      </div>
    </div>
  )
}

export default Divider;